// $primary: #a51616;
$primary: red; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.logo {
	max-width: 300px;
	width: 100%;

	@media (max-width: 990px) {
		max-width: 220px;
	}

}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar .navbar-nav {
    > li > a {
    	font-family: 'Lato', sans-serif;
		text-align: center;
		height: 40px;
		margin-top: 50px;
		font-size: 25px;
		border-radius: 10px;
		display: flex;
		align-items: center;


		@media (max-width: 769px) {
			font-size: 18px;
			margin-top: 2px;
		    height: 40px;
		    display: inline-block;
		}

	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

@mixin btn3 {
	display: inline-block;
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	margin-top: 10px;
	margin-bottom: 20px;

	&:hover {
		text-decoration: none;
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

a.btn3 {
	@include btn3;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.headline {
	display: block;
	font-family: 'Lora', serif;
	font-size: 60px;
	color: white;
	text-align: center;
	line-height: 60px;
	margin-top: 50px;

	@media (max-width: 990px) {
		font-size: 40px;
		line-height: 56px;
	}
}

.header {
	background: -webkit-linear-gradient(rgba(255, 255, 255, 0.65), rgba(0, 0, 0, 1)), rgba(0, 0, 0, 1) url("../img/header.jpg") no-repeat center;
    background: linear-gradient(rgba(255, 255, 255, 0.65), rgba(0, 0, 0, 1)), rgba(0, 0, 0, 1) url("../img/header.jpg") no-repeat center;
	background-size: cover;
	height: 100vh;
	background-position: 38% 20% !important;

	@media (max-width: 767px) {
		height: auto;
		background-position: 30% 10%;
	}
}

.affectionate {
	background-image: url('../img/img1.jpg');
	background-size: cover;
	padding: 170px 0;
	background-position: 10% 40%;
}
.affectionate2 {
	background-image: url('../img/img2.jpg');
	background-size: cover;
	padding: 170px 0;
	background-position: 10% 40%;
}

.container-fluid {
	padding-left: 0px;
	padding-right: 0px;
}

.row {
	margin-left: 0px;
	margin-right: 0px;
}

.col-md-6 {
	padding-left: 0px;
	padding-right: 0px;
}

.process {
	display: block;
	font-family: 'Lora', serif;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-top: 20px;


	@media (max-width: 560px) {
		font-size: 40px;
	}
}

.processp {
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	text-align: center;
	padding-left: 70px;
	padding-right: 70px;

	@media (max-width: 560px) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

.process2 {
	display: block;
	font-family: 'Lora', serif;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-top: 20px;

	@media (max-width: 560px) {
		font-size: 40px;
	}
}

.processp2 {
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	text-align: center;
	padding-left: 70px;
	padding-right: 70px;
	
	@media (max-width: 560px) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

hr {
	border: 1px double #ff0033;
	width: 20%;
}

.bottom {
	background: -webkit-linear-gradient(rgba(255, 255, 255, 0.65), rgba(0, 0, 0, 1)), rgba(0, 0, 0, 1) url("../img/img4.jpg") no-repeat center;
    background: linear-gradient(rgba(255, 0, 0, 0.45), rgba(255, 0, 0, 1)), rgba(0, 0, 0, 1) url("../img/img4.jpg") no-repeat center;
	background-size: cover;
	padding: 10px 0;
	background-position: 10% 15%;
	
	@media (max-width: 560px) {
		background-position: 40% 15%;
	}
}

.outro {
	display: block;
	margin-top: 200px;
	font-size: 90px;
	color: white;
	font-family: 'Mr Dafoe', cursive;
	text-align: center;

	@media (max-width: 550px) {
		line-height: 80px;
	}
}